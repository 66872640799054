import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Image,
  Box,
  //HStack,
} from "@chakra-ui/react";

const ImageButton = ({ src, link, ...props }: any) => (
  //<Box                  /* ４つ固定横並び（従来版） */
  <Box ml="8px" mr="8px"
    onClick={() => {
      window.location.href = link;
    }}
  >
    <Image className= "SlideImage" src={src} {...props} />
  </Box>
);

export const PartsLogoLink = () => {

  const settings = {
    slidesToShow: 5,      // 表示するアイテム数（見えてる）
    infinite: true,       // true:無限スライドする
    autoplay: true,       // 自動スライド:true
    autoplaySpeed: 3000,  // default: 3000 自動スクロールする間隔(ms)
    speed: 500,
    swipeToSlide: true,   // default: false   drag/swipeでslidesToScrollの設定を無視するならtrue
    pauseOnHover: false,  // default: true  Hoverで停止（trueだとスマホでロゴクリックし別サイトに遷移、フラウザバックで戻っても自動スクロールを開始しない）
    dots: false,
    arrows: false,        // default: true
    centerMode: true,
    centerPadding: "0px",
  }

  return (
    // Slider : 3 or 5つ以上が望ましい
    // 5つ以上横並び(Slider)（JARTIC, Nx西iHighway, Nx中iHighway, ドラとら, mewti, [阪神]）
    <Box className="SliderPart">
      <Slider className="SliderSection" {...settings}>
        <ImageButton
          src={`${process.env.PUBLIC_URL}/images/jartic_banner_01.png`}
          link="https://www.jartic.or.jp/"
        />
        <ImageButton
          src={`${process.env.PUBLIC_URL}/images/w-ihighway.png`}
          link="https://www.w-nexco.co.jp/realtime/"
          transform="translateY(6px)"
        />
        <ImageButton
          src={`${process.env.PUBLIC_URL}/images/c-ihighway.png`}
          link="https://www.c-ihighway.jp/pcsite/"
          transform="translateY(7px)"
        />
        <ImageButton
          src={`${process.env.PUBLIC_URL}/images/logo_dratra.png`}
          link="https://www.drivetraffic.jp/"
          transform="translateY(3px)"
        />
        <ImageButton
          src={`${process.env.PUBLIC_URL}/images/mewti.png`}
          link="https://search.shutoko-eng.jp/rtmap.html"
          transform="translateY(2px)"
        />
        {/* 阪神高速(0N/OFF) 2025/2追加予定 ⇒ コメントアウトでリスト表示無し */}
        <ImageButton
          src={`${process.env.PUBLIC_URL}/images/840go.png`}
          link="https://www.8405.jp"
          transform="translateY(4px)"
        />
      </Slider>
    </Box>

    // ４つ固定横並び（（従来版 暖候期）: Nx西iHighway, Nx中iHighway, ドラとら, mewti）
    // <HStack m={4} alignItems="center">
    //   <ImageButton
    //     src={`${process.env.PUBLIC_URL}/images/w-ihighway.png`}
    //     link="https://www.w-nexco.co.jp/realtime/"
    //   />
    //   <ImageButton
    //     src={`${process.env.PUBLIC_URL}/images/c-ihighway.png`}
    //     link="https://www.c-ihighway.jp/pcsite/"
    //   />
    //   {/* <ImageButton
    //     src={`${process.env.PUBLIC_URL}/images/logo_dratra.png`}
    //     w="170px"
    //     link="https://www.drivetraffic.jp/"
    //   /> */}
    //   <ImageButton
    //     src={`${process.env.PUBLIC_URL}/images/logo_drapla.png`}
    //     w="300px"
    //     link="https://www.driveplaza.com/"
    //   />
    //   <ImageButton
    //     src={`${process.env.PUBLIC_URL}/images/mewti.png`}
    //     w="200px"
    //     link="https://search.shutoko-eng.jp/rtmap.html"
    //     transform="translateY(-4px)"
    //   />
    // </HStack>
  )
}
